"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useTokenDelete(params) {
    const { tokenValues, onTokenValuesChange, focusTokenCreator } = params;
    const deleteToken = (0, react_1.useCallback)((targetIndex) => {
        const newTokenValues = [...tokenValues];
        newTokenValues.splice(targetIndex, 1);
        onTokenValuesChange === null || onTokenValuesChange === void 0 ? void 0 : onTokenValuesChange(newTokenValues);
        focusTokenCreator();
    }, [tokenValues, onTokenValuesChange, focusTokenCreator]);
    const handleTokenDelete = (0, react_1.useCallback)((targetIndex) => () => {
        deleteToken(targetIndex);
    }, [deleteToken]);
    const handleLastTokenDelete = (0, react_1.useCallback)(() => {
        deleteToken(-1);
    }, [deleteToken]);
    return {
        handleTokenDelete,
        handleLastTokenDelete,
    };
}
exports.default = useTokenDelete;
