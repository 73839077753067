"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DEFAULT_INPUT_INIT_VALUE = exports.JS__TOKEN__DELETE_BUTTON__CLASS_NAME = exports.DEFAULT_SPECIAL_KEY_DOWN_CONFIG = exports.KEY_DOWN_HANDLER_CONFIG_OPTION = exports.DEFAULT_SEPARATORS = void 0;
exports.DEFAULT_SEPARATORS = [
    ',',
    ';',
    '\n',
    '\r',
    '\r\n',
];
exports.KEY_DOWN_HANDLER_CONFIG_OPTION = Object.freeze({
    OFF: 0,
    ON: 1,
});
exports.DEFAULT_SPECIAL_KEY_DOWN_CONFIG = {
    onBackspace: exports.KEY_DOWN_HANDLER_CONFIG_OPTION.ON,
    onTab: exports.KEY_DOWN_HANDLER_CONFIG_OPTION.OFF,
    onEnter: exports.KEY_DOWN_HANDLER_CONFIG_OPTION.ON,
    onEscape: exports.KEY_DOWN_HANDLER_CONFIG_OPTION.ON,
};
exports.JS__TOKEN__DELETE_BUTTON__CLASS_NAME = 'js__token__delete-button';
exports.DEFAULT_INPUT_INIT_VALUE = '';
