"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const defaultGetTokenErrorMessage = (_, tokenMeta) => {
    const { error } = tokenMeta;
    if (typeof error === 'string') {
        return error;
    }
    return undefined;
};
exports.default = defaultGetTokenErrorMessage;
