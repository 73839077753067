"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const constants_1 = require("../constants");
function useTokenCreatorRef() {
    const tokenCreatorRef = (0, react_1.useRef)(null);
    const focusTokenCreator = (0, react_1.useCallback)((options) => { var _a; return (_a = tokenCreatorRef.current) === null || _a === void 0 ? void 0 : _a.focus(options); }, []);
    const setCreatorValue = (0, react_1.useCallback)((value) => { var _a; return (_a = tokenCreatorRef.current) === null || _a === void 0 ? void 0 : _a.setValue(value); }, []);
    const getCreatorValue = (0, react_1.useCallback)(() => {
        var _a, _b;
        return (_b = (_a = tokenCreatorRef.current) === null || _a === void 0 ? void 0 : _a.getValue()) !== null && _b !== void 0 ? _b : constants_1.DEFAULT_INPUT_INIT_VALUE;
    }, []);
    const createTokens = (0, react_1.useCallback)((value) => { var _a; return (_a = tokenCreatorRef.current) === null || _a === void 0 ? void 0 : _a.createTokens(value); }, []);
    return {
        tokenCreatorRef,
        focusTokenCreator,
        setCreatorValue,
        getCreatorValue,
        createTokens,
    };
}
exports.default = useTokenCreatorRef;
