"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const buildTokenMeta = (customizeError, tokenValue, tokenIndex) => {
    return {
        key: `${JSON.stringify(tokenValue)}-${Date.now()}-${tokenIndex}`,
        activated: false,
        error: customizeError,
    };
};
exports.default = buildTokenMeta;
