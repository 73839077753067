"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
const constants_1 = require("../constants");
const handleKeyDown = ({ keyDownHandlerConfig, predefinedHandler, }) => {
    switch (true) {
        case keyDownHandlerConfig === constants_1.KEY_DOWN_HANDLER_CONFIG_OPTION.ON:
            predefinedHandler();
            break;
        default:
    }
};
function usePredefinedKeyDownHandlers(params) {
    const { specialKeyDownConfig, inputInitValue, inputValue, onLastTokenDelete, handleInputValueUpdate, handleTokensCreate, } = params;
    const { onBackspace, onTab, onEnter, onEscape, } = Object.assign(Object.assign({}, constants_1.DEFAULT_SPECIAL_KEY_DOWN_CONFIG), specialKeyDownConfig);
    const handleBackspaceKeyDown = (0, react_1.useCallback)(() => {
        handleKeyDown({
            keyDownHandlerConfig: onBackspace,
            predefinedHandler: () => {
                if (inputValue.length === 0) {
                    onLastTokenDelete();
                }
            },
        });
    }, [onBackspace, inputValue, onLastTokenDelete]);
    const handleTabKeyDown = (0, react_1.useCallback)((keyDownEvent) => {
        handleKeyDown({
            keyDownHandlerConfig: onTab,
            predefinedHandler: () => {
                keyDownEvent.preventDefault();
                handleTokensCreate(inputValue);
            },
        });
    }, [onTab, inputValue, handleTokensCreate]);
    const handleEnterKeyDown = (0, react_1.useCallback)(() => {
        handleKeyDown({
            keyDownHandlerConfig: onEnter,
            predefinedHandler: () => {
                handleTokensCreate(inputValue);
            },
        });
    }, [onEnter, inputValue, handleTokensCreate]);
    const handleEscapeKeyDown = (0, react_1.useCallback)(() => {
        handleKeyDown({
            keyDownHandlerConfig: onEscape,
            predefinedHandler: () => {
                handleInputValueUpdate(inputInitValue);
            },
        });
    }, [onEscape, inputInitValue, handleInputValueUpdate]);
    return {
        handleBackspaceKeyDown,
        handleTabKeyDown,
        handleEnterKeyDown,
        handleEscapeKeyDown,
    };
}
exports.default = usePredefinedKeyDownHandlers;
