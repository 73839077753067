"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useTokenInputFocusEffect(params) {
    const { onCreatorFocus, onCreatorBlur } = params;
    const [isTokenInputFocused, setIsTokenInputFocused] = (0, react_1.useState)(false);
    const handleTokenInputFocus = (0, react_1.useCallback)(() => {
        setIsTokenInputFocused(true);
    }, []);
    const handleTokenInputBlur = (0, react_1.useCallback)(() => {
        setIsTokenInputFocused(false);
    }, []);
    const handleCreatorFocus = (0, react_1.useCallback)((e) => {
        handleTokenInputFocus();
        onCreatorFocus === null || onCreatorFocus === void 0 ? void 0 : onCreatorFocus(e);
    }, [onCreatorFocus, handleTokenInputFocus]);
    const handleCreatorBlur = (0, react_1.useCallback)((e) => {
        handleTokenInputBlur();
        onCreatorBlur === null || onCreatorBlur === void 0 ? void 0 : onCreatorBlur(e);
    }, [onCreatorBlur, handleTokenInputBlur]);
    return {
        isTokenInputFocused,
        handleTokenInputFocus,
        handleTokenInputBlur,
        handleCreatorFocus,
        handleCreatorBlur,
    };
}
exports.default = useTokenInputFocusEffect;
