"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const react_1 = require("react");
function useTokenMetas() {
    const [tokenMetas, setTokenMetas] = (0, react_1.useState)([]);
    const setTokenActivated = (0, react_1.useCallback)((targetIndex, activated) => {
        if (targetIndex >= tokenMetas.length) {
            throw new Error(`setTokenActivated out of tokenMetas scope; targetIndex ${targetIndex}; tokenMetas.length ${tokenMetas.length}`);
        }
        const newTokenMetas = [...tokenMetas];
        newTokenMetas[targetIndex].activated = activated;
        setTokenMetas(newTokenMetas);
    }, [tokenMetas]);
    return {
        tokenMetas,
        setTokenMetas,
        setTokenActivated,
    };
}
exports.default = useTokenMetas;
